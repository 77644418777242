<template>
  <Dialog v-model:visible="store.successDialogOpen" modal dismissableMask :draggable="false" style="width:450px">
    <template #header>
      <div class="d-flex align-items-center gap-2 cursor-default">
        <div class="wrap-icon success">
          <i class="bi bi-check-lg mr-3" style="font-size: 1.65rem"></i>
        </div>
        <div class="p-dialog-title fs-4">Enhorabuena!</div>
      </div>
    </template>
    <div class="d-flex align-items-center justify-content-center py-3">
      <div class="fs-5 text-center cursor-default">Has hecho un gran trabajo. ¡Eres lo mejor que hay! 😊</div>
    </div>
    <template #footer>
      <div class="d-flex align-items-center justify-content-center">
        <Button class="btn btn-primary" label="Aceptar" rounded @click="handleClose"></Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { useGlobalStore } from '@/stores'
const store = useGlobalStore()

const handleClose = () => {
  store.toggleSuccessDialog(false)
  store.restart();
}

</script>