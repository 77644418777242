import keycloak from "@/keycloak";
import moment from "moment";
import {
  env,
  aggregations,
  CALENDAR_EVENT_FORMAT,
  USER_TIMEZONE,
  countryNames,
} from "./helpers";

const setEpisodeObj = (episode) =>
  localStorage.setItem("episode", JSON.stringify(episode));

const getEpisodeObj = () => {
  const episode = localStorage.getItem("episode");
  if (!episode) return null;
  return JSON.parse(episode);
};

const createEpisode = (episode) => {
  try {
    if (!episode) {
      throw new Error('Missing required "episode" argument');
    }

    // si viene episodeId es un actualizar de un episodio que ya existia y se cargo
    if (episode.episodeId) {
      return updateEpisode(episode);
    }

    const episodeId = crypto.randomUUID();
    const data = { ...episode, episodeId, isPublished: false };

    setEpisodeObj(data);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

const updateEpisode = (episode) => {
  try {
    if (!episode || !episode.episodeId) {
      throw new Error('Missing required "episodeId" argument');
    }

    const savedEpisode = getEpisodeObj();

    if (!savedEpisode) {
      throw new Error(`No episode found`);
    }

    const newData = { ...savedEpisode, ...episode };
    setEpisodeObj(newData);

    return newData;
  } catch (error) {
    return { error: error.message };
  }
};

const getEpisode = () => {
  try {
    const episode = getEpisodeObj();
    return episode;
  } catch (error) {
    return { error: error.message };
  }
};

const postEpisode = async ({ episode }) => {
  try {
    // obtener info de usuario
    const session_auth = keycloak.getUserSession();

    // enviar episodio con info de usuario al api
    const result = await fetch(env.GEV_API_URL + "/episode/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...episode, session_auth }),
    });

    if (!result.ok) {
      const error = await result.json();
      return { error };
    }

    return await result.json();
  } catch (error) {
    return { error };
  }
};

const getClientsEntities = async () => {
  try {
    const payload = {
      filterDict: [
        {
          field: "startDate",
          op: "!=",
          value: null,
        },
        {
          field: "endDate",
          op: "=",
          value: null,
        },
      ],
      orderDict: [
        {
          field: "startDate",
          order: "desc",
        },
      ],
    };
    const params = new URLSearchParams({
      limit: 10000,
      offset: 1,
      fieldMask: [
        "location.locationId",
        "location.clientId",
        "location.name",
        "partner.partnerGroup.name",
        "partner.name",
        "location.typology.name",
        "location.country",
        "location.locality",
        "location.googlePlaceId",
      ],
    });

    const response = await fetch(
      `${env.ENTITIES_API_URL}/api/v1/user/companies/search?${params}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: keycloak.token,
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      const body = await response.json();

      if (body.errors.length) {
        return { error: body.errors[0].msg };
      } else {
        const clients = body.data.companies.flatMap((company) => {
          const { locations, ...otherCompanyAttrs } = company;
          return locations.map((location) => ({
            location: {
              countryName: countryNames[location.country.toLowerCase()],
              ...location,
            },
            ...otherCompanyAttrs,
          }));
        });

        return clients;
      }
    } else {
      console.log(response);
      return { error: "Error desconocido" };
    }
  } catch (error) {
    return { error: error.message };
  }
};

const getMediaDescriptionVars = async () => {
  return [
    { label: "Nombre", tag: "nombre", description: "nombre de la pyme" },
    {
      label: "Tipología",
      tag: "tipologia",
      description: "tipologia de la pyme",
    },
    {
      label: "Ubicación",
      tag: "ubicacion",
      description: "direccion de la pyme",
    },
  ];
};

const postSchedule = async (body) => {
  try {
    // obtener info de usuario
    const session_auth = keycloak.getUserSession();

    const response = await fetch(`${env.PUE_API_URL}/api/v1/schedules/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...body, session_auth }),
    });

    const schedule = await response?.json();

    if (!response.ok) {
      return {
        error: schedule?.detail || JSON.stringify(schedule),
        code: response.status,
      };
    }

    return schedule;
  } catch (error) {
    console.log("Error at POST call. Err:" + error);
    return { error };
  }
};

const getNextSchedules = async (body) => {
  try {
    // obtener info de usuario
    const session_auth = keycloak.getUserSession();

    const response = await fetch(
      `${env.PUE_API_URL}/api/v1/schedules/executions/next/`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ session_auth, ...body }),
      }
    );

    const nextSchedules = await response?.json();

    if (!response.ok) {
      return {
        error: nextSchedules?.detail || JSON.stringify(nextSchedules),
        code: response.status,
      };
    }

    return nextSchedules;
  } catch (error) {
    console.log("Error at POST call. Err:" + error);
    return { error };
  }
};

const getExecutionsById = async ({ scheduleId, startDate, endDate, view }) => {
  try {
    const params = new URLSearchParams({
      aggregation: aggregations[view],
      end_date: moment(endDate).format(CALENDAR_EVENT_FORMAT),
      start_date: moment(startDate).format(CALENDAR_EVENT_FORMAT),
      set_next_execution: false,
      limit: 1000,
      count: true,
      timezone: USER_TIMEZONE,
    });
    const url = `${env.PUE_API_URL}/api/v1/schedules/${scheduleId}/executions/?${params}`;
    const response = await fetch(url);

    const data = await response.json();
    return data?.results?.next_dates;
  } catch (error) {
    console.log(`Error getting executions by Id: `, error);
    return { error };
  }
};

const services = {
  createEpisode,
  updateEpisode,
  getEpisode,
  postEpisode,
  getClientsEntities,
  getMediaDescriptionVars,
  postSchedule,
  getNextSchedules,
  getExecutionsById,
};

export default services;
