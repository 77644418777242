<template>
  <Sidebar v-model:visible="store.scheduleSidebarOpen" @hide="onSidebarHide" position="right" class="sd-scheduler">
    <template #header>
      <h2 class="fs-4 cursor-default">Ejecuci&oacute;n de Evento</h2>
    </template>
    <form id="schedule-form" @submit.prevent="onSubmit">
      <div class="card-body p-0 wrap-program">
        <div class="scheduler-box d-flex flex-column">
          <div class="scheduler-title-box mb-4"><span>Generales</span></div>
          <div class="form-group mb-4">
            <div class="row gap-4 align-items-end">
              <div class="col-sm-4">
                <label class="col-form-label me-2">Tipo de programación:</label>
                <Dropdown class="w-100" placeholder="Tipo de programación" v-model="schedule.scheduleType"
                  :options="scheduleTypeOptions" optionLabel="name" optionValue="value" />
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-once" v-if="schedule.scheduleType === scheduleTypesValues.onetime">
          <div class="scheduler-box d-flex flex-column">
            <div class="scheduler-title-box mb-4"><span>Ocurrecia única</span></div>
            <div class="form-group">
              <div class="row gap-4">
                <div class="col-sm-4">
                  <label class="col-form-label">Fecha:</label>
                  <div>
                    <Calendar v-model="schedule.oneTimeOccurrenceDate" showIcon dateFormat="yy/mm/dd" :minDate="minDate"
                      placeholder="yyyy/mm/dd" class="sh-date-one w-100" required />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">Hora:</label>
                  <div>
                    <Calendar class="sh-time-one w-100" placeholder="hh:mm:ss" hourFormat="24"
                      v-model="schedule.oneTimeOccurrenceTime" timeOnly showIcon showSeconds required>
                      <template #dropdownicon>
                        <i class="pi pi-clock"></i>
                      </template>
                    </Calendar>
                  </div>
                </div>
              </div>
            </div>
            <InlineMessage severity="info" class="mt-3 message-info justify-content-start">
              La fecha y hora de ocurrencia única deben ser posterior que la fecha y hora actuales
            </InlineMessage>
          </div>
        </div>
        <div class="wrap-recurrent" v-if="schedule.scheduleType === scheduleTypesValues.recurrent">
          <div class="scheduler-box d-flex flex-column">
            <div class="scheduler-title-box mb-4"><span>Frecuencia</span></div>
            <div class="form-group m-0">
              <div class="row d-flex align-items-center mb-3">
                <label class="col-sm-2 col-form-label">Ocurre:</label>
                <div class="col-sm-6">
                  <Dropdown class="w-50" placeholder="Frequencia de ocurrencia" v-model="schedule.recurrentFrequency"
                    :options="recurrentFrequencyOptions" optionLabel="name" optionValue="value" />
                </div>
              </div>
              <div class="wrap-occurs-daily" v-if="schedule.recurrentFrequency === recurrentFrequencyValues.daily">
                <div class="row d-flex align-items-center">
                  <label class="col-sm-2 col-form-label">Ocurre cada:</label>
                  <div class="col-sm-6 d-flex align-items-center gap-2">
                    <InputNumber v-model="schedule.dailyInterval" showButtons :min="1" :max="100" required />
                    <span>{{ doPlural("Día", schedule.dailyInterval) }}</span>
                  </div>
                </div>
              </div>
              <div class="wrap-occurs-weekly" v-if="schedule.recurrentFrequency === recurrentFrequencyValues.weekly">
                <div class="row d-flex align-items-center mb-3">
                  <label class="col-sm-2 col-form-label">Ocurre cada:</label>
                  <div class="col-sm-6">
                    <div class="d-flex align-items-center gap-2">
                      <InputNumber v-model="schedule.weeklyInterval" showButtons :min="1" :max="100" />
                      <span>{{ doPlural("Semana", schedule.weeklyInterval) }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-8">
                    <div class="row mb-3">
                      <div class="col-sm-3 mb-3" v-for="day of weekdays" :key="day.value">
                        <div class="d-flex align-items-center gap-2">
                          <Checkbox v-model="schedule.weeklyWeekday" :inputId="day.value" name="weekDays"
                            :value="day.value" />
                          <label :for="day.value">{{ day.name }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wrap-occurs-monthly" v-if="schedule.recurrentFrequency === recurrentFrequencyValues.monthly">
                <div class="row mb-3">
                  <div class="col-sm-2 d-flex align-items-center gap-2">
                    <RadioButton v-model="schedule.monthlyOccurrenceType" inputId="monthlyOccurrenceTypesMonthday"
                      name="monthlyOccurrenceTypes" :value="monthlyOccurrenceTypes.monthday" required />
                    <label for="monthlyOccurrenceTypesMonthday" class="col-form-label">Día:</label>
                  </div>
                  <div class="col-sm-10 d-flex align-items-center gap-3"
                    :class="schedule.monthlyOccurrenceType != monthlyOccurrenceTypes.monthday ? 'sh-disabled' : ''">
                    <InputNumber v-model="schedule.monthlyOccurrenceMonthDay" showButtons :min="1" :max="31" />
                    <span>de cada</span>
                    <InputNumber v-model="schedule.monthlyOccurrenceMonth" showButtons :min="1" :max="12" />
                    <span>{{ doPlural("Mes", schedule.monthlyOccurrenceMonth, "es") }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-2 d-flex align-items-center gap-2">
                    <RadioButton v-model="schedule.monthlyOccurrenceType" inputId="monthlyOccurrenceTypesWeekday"
                      name="monthlyOccurrenceTypes" :value="monthlyOccurrenceTypes.weekday" required />
                    <label for="monthlyOccurrenceTypesWeekday" class="col-form-label">El:</label>
                  </div>
                  <div class="col-sm-10 d-flex align-items-center gap-3"
                    :class="schedule.monthlyOccurrenceType != monthlyOccurrenceTypes.weekday ? 'sh-disabled' : ''">
                    <Dropdown v-model="schedule.monthlyOccurrenceWeekdayNumber"
                      :options="monthlyOccurrenceWeekayNumberOptions" optionLabel="name" optionValue="value"
                      class="w-25" />
                    <Dropdown v-model="schedule.monthlyOccurrenceWeekday" :options="monthlyOccurrenceWeekdayOptions"
                      optionLabel="name" optionValue="value" class="w-25" />
                    <span>de cada</span>
                    <InputNumber v-model="schedule.monthlyOccurrenceWeekdayMonth" showButtons :min="1" :max="12" />
                    <span>{{
                      doPlural("Mes", schedule.monthlyOccurrenceWeekdayMonth, "es")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scheduler-box d-flex flex-column mt-3 mb-4">
            <div class="scheduler-title-box mb-4">
              <span>Frecuencia diaria</span>
            </div>
            <div class="form-group m-0">
              <div class="row mb-3">
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-sm-4 d-flex align-items-center gap-2">
                      <RadioButton v-model="schedule.dailyFrequency" inputId="daily-frequency-once" name="dailyFrequency"
                        :value="dailyFrequenciesValues.once" />
                      <label for="daily-frequency-once" class="col-form-label">Sucede una vez a las:</label>
                    </div>
                    <div class="col-sm-8"
                      :class="schedule.dailyFrequency === dailyFrequenciesValues.once ? '' : 'sh-disabled'">
                      <Calendar id="time-only-02" v-model="schedule.dailyFrequencyTime" timeOnly showIcon showSeconds
                        placeholder="hh:mm:ss" class="sh-happens-once">
                        <template #dropdownicon>
                          <i class="pi pi-clock"></i>
                        </template>
                      </Calendar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-sm-4 d-flex align-items-center gap-2">
                      <RadioButton v-model="schedule.dailyFrequency" inputId="daily-frequency-every" name="dailyFrequency"
                        :value="dailyFrequenciesValues.every" />
                      <label for="daily-frequency-every" class="col-form-label">Sucede cada:</label>
                    </div>
                    <div class="col-sm-8"
                      :class="schedule.dailyFrequency === dailyFrequenciesValues.every ? '' : 'sh-disabled'">
                      <div class="d-flex align-items-center gap-2">
                        <InputNumber v-model="schedule.everyFrequency" inputId="minmax-buttons" mode="decimal" showButtons
                          :min="1" :max="60" />
                        <Dropdown v-model="schedule.everyFrequencyMetric" :options="happensEveryMetricOptions"
                          optionLabel="name" optionValue="value" class="w-50" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5"
                  :class="schedule.dailyFrequency === dailyFrequenciesValues.every ? '' : 'sh-disabled'">
                  <div class="row d-flex align-items-center" style="margin-bottom:2rem">
                    <label class="col-sm-2 col-form-label">Empieza:</label>
                    <div class="col-sm-10 d-flex gap-4">
                      <TimeInput id="starts-hours" label="Hora" :value="schedule.everyFrequencyStartsAtHour" min="00"
                        max="23" @change="(newValue) => handleTimeInputChange(newValue, 'everyFrequencyStartsAtHour')" />

                      <TimeInput id="starts-minutes" label="Minutos" :value="schedule.everyFrequencyStartsAtMinute"
                        min="00" max="59"
                        @change="(newValue) => handleTimeInputChange(newValue, 'everyFrequencyStartsAtMinute')"
                        :disabled="schedule.everyFrequencyMetric === happensEveryMetricValues.hours" />

                     <!--  <TimeInput id="starts-seconds" label="Segundos" :value="schedule.everyFrequencyStartsAtSecond"
                        min="00" max="59"
                        @change="(newValue) => handleTimeInputChange(newValue, 'everyFrequencyStartsAtSecond')"
                        :disabled="schedule.everyFrequencyMetric === happensEveryMetricValues.hours || schedule.everyFrequencyMetric === happensEveryMetricValues.minutes" /> -->
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <label class="col-sm-2 col-form-label">Finaliza:</label>
                    <div class="col-sm-10  d-flex gap-4">
                      <TimeInput id="ends-hours" label="Hora" :value="schedule.everyFrequencyEndsAtHour"
                        :min="schedule.everyFrequencyStartsAtHour" max="23"
                        @change="(newValue) => handleTimeInputChange(newValue, 'everyFrequencyEndsAtHour')" />

                      <TimeInput id="ends-minutes" label="Minutos" :value="schedule.everyFrequencyEndsAtMinute"
                        :min="schedule.everyFrequencyStartsAtMinute" max="59"
                        @change="(newValue) => handleTimeInputChange(newValue, 'everyFrequencyEndsAtMinute')"
                        :disabled="schedule.everyFrequencyMetric === happensEveryMetricValues.hours" />

                      <!-- <TimeInput id="ends-seconds" label="Segundos" :value="schedule.everyFrequencyEndsAtSecond"
                        :min="schedule.everyFrequencyStartsAtSecond" max="59"
                        @change="(newValue) => handleTimeInputChange(newValue, 'everyFrequencyEndsAtSecond')"
                        :disabled="schedule.everyFrequencyMetric === happensEveryMetricValues.hours || schedule.everyFrequencyMetric === happensEveryMetricValues.minutes" /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scheduler-box d-flex flex-column mt-3 mb-4">
            <div class="scheduler-title-box mb-4"><span>Duración</span></div>
            <div class="form-group m-0">
              <div class="row">
                <div class="col-sm-5">
                  <div class="row">
                    <label class="col-sm-5 col-form-label d-flex align-items-center">Fecha de inicio:</label>
                    <div class="col-sm-7">
                      <Calendar id="sh-start-date" v-model="schedule.startDate" :minDate="minDate" showIcon
                        dateFormat="yy/mm/dd" placeholder="yyyy/mm/dd" class="sh-start-date w-100" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mb-3">
                    <div class="col-sm-5 d-flex align-items-center gap-2">
                      <RadioButton v-model="schedule.hasEndDate" inputId="checkEndDate1" name="endDate" :value="true" />
                      <label for="checkEndDate1" class="col-form-label">Fecha de finalización:</label>
                    </div>
                    <div class="col-sm-6" :class="schedule.hasEndDate ? '' : 'sh-disabled'">
                      <Calendar id="sh-end-date" v-model="schedule.endDate" :minDate="minDate" showIcon
                        dateFormat="yy/mm/dd" placeholder="yyyy/mm/dd" class="sh-start-date w-100" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5 d-flex align-items-center gap-2">
                      <RadioButton v-model="schedule.hasEndDate" inputId="checkEndDate2" name="endDate" :value="false" />
                      <label for="checkEndDate2" class="col-form-label">Sin fecha de finalización</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end gap-3 mt-4">
        <Button class="btn btn-primary" label="Cancelar" rounded outlined @click="onSidebarHide"></Button>
        <Button class="btn btn-primary" label="Programar" rounded type="submit" :loading="loading"></Button>
      </div>
    </form>
    <Divider class="mt-4" />
    <div class="mb-6">
      <h6 class="mb-3">Pr&oacute;ximas 5 ejecuciones<i v-if="store.loadingNextSchedules"
          class="ms-2 pi pi-spin pi-spinner"></i>
      </h6>
      <ul v-if="store.preSchedules.next">
        <li v-for="nextSchedule of store.preSchedules.next" :key="nextSchedule">
          {{ nextSchedule }}
        </li>
      </ul>
      <div v-if="store.preSchedules.error">
        <p class="mb-0">Error al calcular próximas ejecuciones:</p>
        <p class="mb-0">{{ store.preSchedules.error }}</p>
      </div>
    </div>
  </Sidebar>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import moment from 'moment'
import { useToast } from 'primevue/usetoast';
import { useGlobalStore } from '@/stores';
import services from '@/helpers/services';
import {
  processesTypes,
  scheduleTypesValues,
  recurrentFrequencyValues,
  monthlyOccurrenceTypes,
  dailyFrequenciesValues,
  monthlyOccurrenceWeekayNumberOptions,
  monthlyOccurrenceWeekdayOptions,
  happensEveryMetricValues,
  happensEveryMetricOptions,
  weekdays,
  scheduleTypeOptions,
  recurrentFrequencyOptions,
  doPlural,
  debounce,
  getSchedulesAttributes
} from '@/helpers/helpers'

const toast = useToast();
const currentDate = ref(moment());
const loading = ref(false);
const schedule = reactive({
  scheduleType: scheduleTypesValues.onetime,
  oneTimeOccurrenceDate: currentDate.value.toDate(),
  oneTimeOccurrenceTime: currentDate.value.toDate(),
  recurrentFrequency: recurrentFrequencyValues.daily,
  dailyInterval: 1,
  weeklyInterval: 1,
  weeklyWeekday: [],
  monthlyOccurrenceType: "",
  monthlyOccurrenceMonthDay: 1,
  monthlyOccurrenceMonth: 1,
  monthlyOccurrenceWeekdayNumber: "",
  monthlyOccurrenceWeekday: "",
  monthlyOccurrenceWeekdayMonth: 1,
  dailyFrequency: dailyFrequenciesValues.once,
  dailyFrequencyTime: "",
  everyFrequency: 1,
  everyFrequencyMetric: happensEveryMetricOptions[0].value,
  everyFrequencyStartsAtHour: '00',
  everyFrequencyStartsAtMinute: '00',
  everyFrequencyStartsAtSecond: '00',
  everyFrequencyEndsAtHour: '00',
  everyFrequencyEndsAtMinute: '00',
  everyFrequencyEndsAtSecond: '00',
  startDate: currentDate.value.toDate(),
  endDate: "",
  hasEndDate: false,
})

const store = useGlobalStore()

const minDate = new Date()

const debouncedFetch = debounce(async () => {
  await store.fetchNextSchedules({ schedule })
}, 2000);

watch(schedule, () => {
  store.loadingNextSchedules = true;
  debouncedFetch()
})

const onSidebarHide = () => {
  store.toggleScheduleSidebar(false)
}

const handleTimeInputChange = (newValue, propToUpdate) => {
  schedule[propToUpdate] = newValue;
}

const onSubmit = async () => {
  // poner loader
  loading.value = true

  // transformar el schedule en el objeto que espera el backend
  const attributes = getSchedulesAttributes({ schedule })

  // luego de las transformaciones, enviar al backend
  const query = {
    attributes,
    process_type: processesTypes.pueProgramming,
  }

  const response = await services.postSchedule(query);

  if (response.error) {
    toast.add({ severity: 'error', summary: `Error ${response.code}`, detail: response.error });
    loading.value = false;
  } else {
    store.addEpisodeSchedule(response.id)

    const { error, ...episode } = services.updateEpisode({
      episodeId: store.episode.episodeId,
      schedules: store.episode.schedules,
    })

    if (error) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Programación creada, pero no guardada en episodio actual' });
      loading.value = false
      return;
    }

    store.setLastSavedEpisode(episode)

    loading.value = false;

    // get the next executions for this schedule
    store.fetchScheduleEvents();

    onSidebarHide();
    toast.add({ severity: 'success', summary: 'Info', detail: 'Programación creada', life: 3000 });
  }
}
</script>
